import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { bindActionCreators } from "redux";

import DevTools from "./app/config/devtools";
import initStore from "./app/config/store";
import setupAxiosInterceptors from "./app/config/axios-interceptor";
import { clearAuthentication } from "./app/reducers/authentication";
import ErrorBoundary from "./app/shared/error/error-boundary";
import App from "./App";
import { loadIcons } from "./app/config/icon-loader";
import RefreshBoundary from '@pmmmwh/react-refresh-webpack-plugin';

const devTools = process.env.NODE_ENV === "development" ? <DevTools /> : null;

const store = initStore();
const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() =>
  actions.clearAuthentication("login.error.unauthorized")
);

loadIcons();

const rootEl = document.getElementById("root");

const render = Component =>
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <div>
          {/* If this slows down the app in dev disable it and enable when required  */}
          {devTools}
          <Component />
        </div>
      </Provider>
    </ErrorBoundary>,
    rootEl
  );

render(App);
/*
render(
  <RefreshBoundary>
    <App />
  </RefreshBoundary>,
  document.getElementById('root')
);*/
