import React from "react";
import { Box, LinearProgress, Typography } from "@mui/material";

interface CryptoGaugeProps {
    perc: number | null | undefined;
    reverse: boolean;
    viewMode: boolean;
  }

  const CryptoGauge: React.FC<CryptoGaugeProps> = ({ perc,reverse,viewMode }) => {
    if(perc==null || isNaN(perc)){
      return <>Inconnu</>;
    }
    let level=0;
    if(reverse){

    }else{
    if(perc<25){
      level=0;
    }else if(perc<50){
      level=1;
    }else if(perc<75){
      level=2;
    }else if(perc<100){
      level=3;
    }else{
      level=4;
    }
  }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }} className={"gauge"}>
        <Box sx={{ width: '100%' }}></Box>
        <Box sx={{ minWidth: '50px', mr: 1 }} className={"gaugeContainer gauge"+level+(!viewMode?" editmode":"")}>
          <LinearProgress variant="determinate" value={perc} color="inherit"/>
        </Box>
        <br/>
        <Box sx={{ minWidth: 35 }} className="gaugePerc">
          {viewMode &&    <Typography variant="body2" color="text.secondary">{reverse?'X':''}{`${perc.toFixed(2)}`}{reverse?'':'%'}</Typography>}
          {(!viewMode) &&    <Typography variant="body2" color="text.secondary">&nbsp;</Typography>}
        </Box>
        </Box>
    );
  }

  export default CryptoGauge;
 