import React from "react";
import "../CryptoTable.css";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import { CryptoTableRows, colors } from "../data/CryptoDataType";
import { Row, Col, Table } from "reactstrap";
import { calculBarChartData, cloneItems, groupBarChartData } from "./CryptoGraphUtils";

interface ChildProps {
    rows: CryptoTableRows;
    tutu?: any;
}

let rowsToto: any = [];
let rowsTotoTarget: any = [];


const getArcLabel = (params: any) => {
    if (rowsToto != null && rowsTotoTarget != null) {
        let TOTAL = 0;
        for (let item of rowsToto) {
            TOTAL = TOTAL + item.value;
        }
        return `${params.label2}`;
    } else {
        return '';
    }

};
const getArcLabelTarget = (params: any) => {
    if (rowsToto != null && rowsTotoTarget != null) {
        let TOTAL = 0;
        for (let item of rowsTotoTarget) {
            TOTAL = TOTAL + item.value;
        }
        return `${params.label2}`;
    } else {
        return '';
    }

};

const renderPie = (data: any, target: boolean) => {
    return <PieChart
        colors={colors}
        slotProps={{
            legend: {
                padding: 0,
            },
        }}
        sx={{
            [`& .${pieArcLabelClasses.root}`]: {
                fill: 'black',
                fontSize: '14px',
                fontWeight: 'bold'
            },
        }}
        series={[
            {
                outerRadius: 380,
                innerRadius: 30,
                paddingAngle: 1,
                cornerRadius: 5,
                arcLabelRadius: 370,
                arcLabel: (target ? getArcLabelTarget : getArcLabel),
                cx: 500,
                data
            },
        ]}

        height={800}
    />;
}

const CryptoPiePerSymbol: React.FC<ChildProps> = (props: ChildProps) => {
    let rows = { items: [] };
    cloneItems(props,rows);
   

    let data: any = [];
    rowsToto = [];
    let dataTarget: any = [];
    rowsTotoTarget = [];
    let total = 0;
    let totalTarget = 0;
    let groupedData: any = [];
    let groupedDataTarget: any = [];

    if (rows.items) {
        calculBarChartData(rowsToto, rowsTotoTarget, rows, data, dataTarget);
        groupBarChartData(data, dataTarget,groupedData,groupedDataTarget);

        for (let item of groupedData) {
            total += item.value;
            item.label = item.label2 + ' $' + item.value.toFixed(0)
        }
        for (let item of groupedDataTarget) {
            totalTarget += item.maxTarget;
            item.value = item.maxTarget;
            item.label = item.label2 + ' $' + item.value.toFixed(0)
        }
    }


    return (
        <span>
            <Table className="cryptoPie" id="cryptoPie">
                <Row className="cryptoPieRow">
                    <Col md="12" className="cryptoPieItemExpected">
                        Portfolio actuel: ${total.toFixed(0)} USDT
                    </Col>

                </Row>
                <Row className="cryptoPieRow">
                    <Col md="12" className="cryptoPieItem">
                        {renderPie(groupedData, false)}
                    </Col>
                </Row>
                <Row className="cryptoPieRow">
                    <Col md="12" className="cryptoPieItemExpected">
                        Portfolio Bullrun: ${totalTarget.toFixed(0)} USDT
                    </Col>

                </Row>
                <Row className="cryptoPieRow">
                    <Col md="12" className="cryptoPieItem">
                        {renderPie(groupedDataTarget, true)}
                    </Col>
                </Row>
            </Table></span>);
};

export default CryptoPiePerSymbol;