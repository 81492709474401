import axios from "axios";
import { CryptoTableRows, Porttcfolio } from "./CryptoDataType";
import { porttcfolio, updatePorttcfolio } from "./CryptoData";
import { getCryptoValue, setCrypto } from "./CryptoCache";


const delay = (ms: any) => new Promise(res => setTimeout(res, ms));

export const queryCoinGecko = async (setTtcCrypto: any, filterData: any) => {

  while (notAllLoaded()) {
    await delay(5000);
  }

  axios
    .get(
      "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=2000&page=1&sparkline=false"
    )
    .then((res) => {
      let data: any;
      data = res.data;
      let result: CryptoTableRows;
      for (let j = 0; j < data.length; j++) {
        let srcData = data[j];
        srcData.symbol = srcData.symbol.toUpperCase();
      }
      result = { items: filterData(data) };
      setTtcCrypto(result);
    })
    .catch((error) => {
      let result: CryptoTableRows;
      result = { items: filterData([]) };
      setTtcCrypto(result);
    });
}

export async function queryCoinbase(symbol: string, pf: any) {
  try {
    let cryptoPrice = getCryptoValue(symbol);
    if (cryptoPrice != null) {
      pf.current_price = cryptoPrice;
      pf.loaded = true;
    } else {
      const res = await axios.get("https://api.coinbase.com/v2/prices/" + symbol + "-USD/spot");

      if (typeof pf.current_price === 'string') {
        pf.current_price = Number(pf.current_price);
      } else {
        pf.current_price = res.data.data.amount;
      }
      pf.loaded = true;
      setCrypto(symbol, pf.current_price, pf.coingeckoId, pf.history,null);
    }
  } catch (e) {
    pf.current_price = 0;
    getGeekiPrice(symbol.toUpperCase(), pf);
  }

}


export async function loadPrices(porttcfolio: Porttcfolio) {
  let symbols = '';
  for (let i = 0; i < porttcfolio.items.length; i++) {
    let pf = porttcfolio.items[i];
    if (symbols !== '') {
      symbols = symbols + '_';
    }
    symbols = symbols + pf.symbol;
    try {
    } catch (e) {
      console.log(e);
    };
  }

  getGeekiPrices(symbols, porttcfolio);
}

export const getGeekiPrices = (symbols: string, porttcfolio: Porttcfolio) => {
  if (symbols != null && symbols !== undefined && symbols !== "") {
    symbols=symbols.toUpperCase();
    axios
      .get(
        "https://www.geeki.fr/api/cryptos/" + symbols, {
        withCredentials: false,
        headers: {
          'Accepts': 'application/json',
          'Access-Control-Allow-Credentials': true,
          "Access-Control-Allow-Origin": "*",
          'Access-Control-Allow-Headers': 'Origin'
        }
      })
      .then((res) => {
        let resData = res.data;
        for (let i = 0; i < resData.length; i++) {
          let data = resData[i];
          let pf = porttcfolio.items;
          for (let j = 0; j < pf.length; j++) {

            if (pf[j].symbol.toUpperCase() === data.symbol.toUpperCase()) {

              pf[j].symbol = data.symbol.toUpperCase();
              pf[j].name = data.name;
              if (pf[j].symbol.toUpperCase() === 'TRUMP2024') {
                pf[j].current_price = 0;
              } else {
                pf[j].current_price = data.price;
              }

              pf[j].price_change_percentage_24h = data.price_change_percentage_24h;
              pf[j].market_cap = data.market_cap;
              pf[j].market_cap_rank = data.market_cap_rank;


              pf[j].history = data.history;
              pf[j].loaded = true;
              pf[j].coingeckoId = data.coingeckoId;
              setCrypto(data.symbol, pf[j].current_price, data.coingeckoId, pf[j].history,data.price_change_percentage_24h);
            }
          }

        }
        let strPorttcfolio = JSON.stringify(porttcfolio);
        updatePorttcfolio(strPorttcfolio);
        localStorage.setItem("cryptool", strPorttcfolio);

        doCoinbases(porttcfolio);
      })
      .catch((error) => {
        let strPorttcfolio = JSON.stringify(porttcfolio);
        updatePorttcfolio(strPorttcfolio);
        localStorage.setItem("cryptool", strPorttcfolio);
      })
  }
}


export async function doCoinbases(porttcfolio: Porttcfolio) {
  for (let i = 0; i < porttcfolio.items.length; i++) {
    let pf = porttcfolio.items[i];
    if (!pf.loaded) {
      try {
        queryCoinbase(pf.symbol, pf);
      } catch (e) {
        console.log(e);
        pf.loaded = true;
      };
    }
  }

}

export const notAllLoaded = () => {
  for (let i = 0; i < porttcfolio.items.length; i++) {
    let pf = porttcfolio.items[i];
    if (!pf.loaded) {
      return true;
    }
  }
  return false;
}


export const getGeekiPrice = (symbol: string, pf: any) => {
  axios
    .get(
      "https://www.geeki.fr/api/crypto/" + symbol, {
      withCredentials: false,
      headers: {
        'Accepts': 'application/json',
        'Access-Control-Allow-Credentials': true,
        "Access-Control-Allow-Origin": "*",
        'Access-Control-Allow-Headers': 'Origin'
      }
    })
    .then((res) => {
      let data: any = res.data;
      pf.current_price = data.price;
      pf.name = data.name;
      pf.current_price = data.price;
      pf.price_change_percentage_24h = data.price_change_percentage_24h;
      pf.market_cap = data.market_cap;
      pf.market_cap_rank = data.market_cap_rank;
      pf.history = data.history;
      pf.loaded = true;
      setCrypto(symbol, pf.current_price, pf.coingeckoId, pf.history,data.price_change_percentage_24h);
      for(let i=0;i<porttcfolio.items.length;i++){
        if(porttcfolio.items[i].ttcId===pf.ttcId){
          porttcfolio.items[i]=pf;
          break;
        }
      }
    })
    .catch((error) => {
      pf.current_price = 0;
      pf.loaded = true;
      for(let i=0;i<porttcfolio.items.length;i++){
        if(porttcfolio.items[i].ttcId===pf.ttcId){
          porttcfolio.items[i]=pf;
          break;
        }
      }
    })

}
