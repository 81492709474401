import React from "react";
import "../CryptoTable.css";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import CryptoRow from "./CryptoRow";
import CryptoTableHeader from "./CryptoTableHeader";
import { CryptoTableRow, CryptoTableRows, StatusEnum, TargetData, generateTtcId } from "../data/CryptoDataType";
import { updatePorttcfolio, getPositionInSortedRows, porttcfolio } from "../data/CryptoData";

const CryptoTable = (props: any) => {

  let rows: CryptoTableRows = props.cryptoRows.cryptoRows;

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  let [updated, setUpdated] = React.useState(false);

  const toggleUpdated = () => {
    setUpdated(!updated);
  }

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const onRemoveTarget = (ttcId: string, targetPos: number) => {
    let sortedRow = stableSort(rows.items, getComparator(order, orderBy));
    let pos = getPositionInSortedRows(ttcId, sortedRow);
    let row: any = sortedRow[pos];
    row.targets.splice(targetPos, 1);
    porttcfolio.items=sortedRow;
    let strPorttcfolio = JSON.stringify(porttcfolio);
    updatePorttcfolio(strPorttcfolio);
    localStorage.setItem("cryptool", strPorttcfolio);
    props.partialRefresh(false);
  }

  const onRemove = (ttcId: string) => {
    if (window.confirm("Etes vous sur de vouloir supprimer la ligne?")) {
      let sortedRow = stableSort(rows.items, getComparator(order, orderBy));
      let pos = getPositionInSortedRows(ttcId, sortedRow);
      sortedRow.splice(pos, 1);
      porttcfolio.items=sortedRow;
      let strPorttcfolio = JSON.stringify(porttcfolio);
      updatePorttcfolio(strPorttcfolio);
      localStorage.setItem("cryptool", strPorttcfolio);
      props.partialRefresh(false);
    }
  }

  const onAdd = () => {
    let strPorttcfolioItems= JSON.stringify(rows.items);
    if (strPorttcfolioItems.trim().replaceAll(' ', '') === '[]') {
      strPorttcfolioItems = "[{\"ttcId\":\"" + generateTtcId() + "\",\"symbol\":\"XXX\",\"site\":\"xxx\",\"bag\":	0,\"targets\":[]}]";
    } else {
      strPorttcfolioItems = "[" +
        "{\"ttcId\":\"" + generateTtcId() + "\",\"symbol\":\"XXX\",\"site\":\"xxx\",\"bag\":	0,\"targets\":[]},"

        + strPorttcfolioItems.substring(1);
    }
    porttcfolio.items=JSON.parse(strPorttcfolioItems);
    let strPorttcfolio = JSON.stringify(porttcfolio);
    updatePorttcfolio(strPorttcfolio);
    localStorage.setItem("cryptool", strPorttcfolio);
    props.partialRefresh(false);
  }

  const partialRefresh = () => {
    props.partialRefresh(false);
  }


  function descendingComparator(a: any, b: any, orderBy: any) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator(order: any, orderBy: any) {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy)
  }

  function stableSort(array: any, comparator: any):CryptoTableRow[] {
    const stabilizedThis = array.map((el: any, index: any) => [el, index])
    stabilizedThis.sort((a: any, b: any) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    let toto = stabilizedThis.map((el: any) => el[0])
    return Array.from(toto.values());
  }


  let sortedRow = stableSort(rows.items, getComparator(order, orderBy));
  let arrayRows = [];
  for (let i = 0; i < sortedRow.length; i++) {
    let row: CryptoTableRow;
    row = sortedRow[i] as CryptoTableRow;
    arrayRows.push(<CryptoRow pos={i} item={row} rows={rows.items}
      buyPopupRef={props.buyPopupRef}
      viewMode={props.viewMode}
      partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
      onAddTarget={toggleUpdated}
      onRemoveTarget={onRemoveTarget}
      onRemove={onRemove} key={"cryptorow" + row.name + row.site + Math.random()} />);
  }

  return (rows.items ?
    <TableContainer component={Paper} sx={{ minHeight: 800 }} className="crypto-table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <CryptoTableHeader
          viewMode={props.viewMode}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={rows.items.length}
          onAdd={onAdd}
          partialRefresh={props.partialRefresh}
          fullRefresh={props.fullRefresh}
        />
        <TableBody>
          {arrayRows}
        </TableBody>
      </Table>
    </TableContainer> : <></>
  );
};

export default CryptoTable;
