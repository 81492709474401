import { LineChart } from '@mui/x-charts/LineChart';
import { CryptoTableRows } from '../data/CryptoDataType';
import { calculPrice } from '../array/CryptoRow';
import { Tooltip } from '@mui/material';

interface ChildProps {
  cryptoRows?: CryptoTableRows
  investment?: number
}

const CryptoHeaderGraph: React.FC<ChildProps> = ({ cryptoRows,investment }) => {
  if (cryptoRows) {

    let xAxis = [];
    let partialData: any = [];
    let mixedData: any = [];
    let fullData: any = [];
    let soldData: any = [];
    let impotData: any = [];
    let gainData: any = [];
    let netData: any = [];
    for (let i = 1; i <= 100; i++) {
      xAxis.push(i);
    }
    fillGraph(investment,mixedData, partialData, fullData, soldData, impotData, gainData,netData, cryptoRows);

    const CustomItemTooltip = ({ ...props }) => (
      <Tooltip title="Add" arrow>
        <>aaaa</>
      </Tooltip>

    );


    return <div className='crypto-header-graph'>
      <LineChart
        xAxis={[{ data: xAxis }]}
        dataset={mixedData}
        slots={{
          itemContent: CustomItemTooltip
        }}
        series={[
          {
            showMark: false,
            type: 'line',
            dataKey: 'objectif',
            area: true,
            valueFormatter: (v: any) => {
              return `Total: ${v} USDT`;
            },

          },
          
          {
            showMark: false,
            type: 'line',
            dataKey: 'net',
            area: true,


            valueFormatter: (v: any) => {
              return `Net: ${v} USDT`;
            },

          },
          {
            showMark: false,
            type: 'line',
            dataKey: 'gains',
            area: true,


            valueFormatter: (v: any) => {
              return `Gains: ${v} USDT`;
            },

          },
          {
            showMark: false,
            type: 'line',
            dataKey: 'impots',
            area: true,


            valueFormatter: (v: any) => {
              return `Impots: ${v} USDT`;
            },

          },
          {
            showMark: false,
            type: 'line',
            dataKey: 'vendu',
            area: true,


            valueFormatter: (v: any) => {
              return `Vendu: ${v} USDT`;
            },

          },
        ]}
        margin={{
          left: 80,
          right: 10,
          top: 20,
          bottom: 50,
        }}
        width={1000}
        height={200}
      />
    </div>;
  } else {
    return <></>;
  }
};

const fillGraph = (investment1:number|undefined,mixedData: any, partialData: any, fullData: any, soldData: any, impotData: any, gainData: any,netData:any, rows: CryptoTableRows) => {

let investment=0;
if(investment1!==undefined){
  investment=investment1;
}

  for (let i = 0; i < rows.items.length; i++) {
    let item = rows.items[i];
    for (let j = 0; j < item.targets.length; j++) {
      let target = item.targets[j];
      target.graphProcessed = false;
    }
  }

  let fullTotal = 0;
  for (let k = 1; k <= 100; k++) {
    let partialTotal = 0;
    let soldTotal = 0;

    for (let i = 0; i < rows.items.length; i++) {
      let item = rows.items[i];
      let sumPerc = 0;
      for (let j = 0; j < item.targets.length; j++) {
        let target = item.targets[j];
        let bagPerc=0;
        if(target.bagPerc!==undefined){
          bagPerc=target.bagPerc;
        }
        let targetPrice=0;
        if(target.price!==undefined){
          targetPrice=target.price;
        }

        if (sumPerc + bagPerc <= k) {
          sumPerc += bagPerc;
          if (!target.graphProcessed) {
            target.graphProcessed = true;
            fullTotal += targetPrice;
          }
          if (target.sold) {
            soldTotal += targetPrice;
            partialTotal += targetPrice;
          } else {
            partialTotal += targetPrice;
          }
        } else {
          if (!target.graphProcessed) {
            target.graphProcessed = true;
            for (let l = j + 1; l < item.targets.length; l++) {
              let rate: number | null = (item.targets[l].rate === undefined ? null : target.rate);
              fullTotal += calculPrice(item.targets[l].bag, rate);
            }
          }
          break;
        }
      }


    }
    let impotTotal = (partialTotal-investment) * 33 / 100;
    if(impotTotal<0){
      impotTotal=0;
    }
    let gainTotal = partialTotal -investment - impotTotal;
    if(gainTotal<0){
      gainTotal=0;
    }
    let netTotal = partialTotal - impotTotal;
    impotData.push(impotTotal);
    gainData.push(gainTotal);
    netData.push(netTotal);
    partialData.push(partialTotal);
    fullData.push(fullTotal);
    soldData.push(soldTotal);
    mixedData.push({ id: partialTotal | 0, value: partialTotal | 0, objectif: partialTotal | 0, impots: impotTotal | 0, gains: gainTotal | 0,net: netTotal | 0, vendu: soldTotal | 0 }); //int value

  }
}

export default CryptoHeaderGraph;