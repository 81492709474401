import "./app/ReactToastify.css";
import "./app/app.scss";

import { useEffect } from "react";
import { connect } from "react-redux";
import { Card } from "reactstrap";
import { Box } from '@mui/material'
import { HashRouter as Router } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { IRootState } from "./app/reducers";
import { getSession } from "./app/reducers/authentication";
import { getProfile } from "./app/reducers/application-profile";
import LoadingBarWithRibbon from "./app/shared/layout/loadingBarWithRibbon/LoadingBarWithRibbon";
import { hasAnyAuthority } from "./app/shared/auth/private-route";
import ErrorBoundary from "./app/shared/error/error-boundary";
import { AUTHORITIES } from "./app/custom/config/constants";
import AppRoutes from "./app/routes";


export interface IAppProps extends StateProps, DispatchProps {
  isDarkMode: any,
  isAdmin: any,
  isGeeki: any,
  ribbonEnv: any,
  isInProduction: any,
  isSwaggerEnabled: any,
  getSession: any,
  getProfile: any

}

export const App = (props: IAppProps) => {
  const baseHref = document?.querySelector("base")?.getAttribute("href")?.replace(/\/$/, "");
  useEffect(() => {
    try {
    //  props.getSession();
      props.getProfile();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const rootE2 = document.getElementById("root");
  if (rootE2 != null) {
    if (props.isDarkMode) {
      rootE2.classList.add("darkMode");
    } else {
      rootE2.classList.remove("darkMode");
    }
  }
         
  return (
    <Router basename={baseHref}>
      <div
        className={"app-container"}
        id="appContainer"
      ><LocalizationProvider dateAdapter={AdapterDayjs}>
          <ToastContainer 
          autoClose={5000}
          position={toast.POSITION.TOP_RIGHT}
            closeOnClick
pauseOnHover
              theme="light"
          />

          <ErrorBoundary>
            <LoadingBarWithRibbon
              isAdmin={props.isAdmin}
              isGeeki={props.isGeeki}
              ribbonEnv={props.ribbonEnv}
              isInProduction={props.isInProduction}
              isSwaggerEnabled={props.isSwaggerEnabled}
            />
          </ErrorBoundary>
          <div className="container-fluid view-container" id="app-view-container">
            <Card className="jh-card">
              <Box p={2}>
                <ErrorBoundary>
                  <AppRoutes />
                </ErrorBoundary>
              </Box>
            </Card>
          </div>
        </LocalizationProvider>
      </div>

    </Router>
  );
};
/* <script
        async
        src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      />
    </Router>
    */

const mapStateToProps = ({
  authentication,
  applicationProfile
}: IRootState) => ({
  isDarkMode: authentication.account
    ? authentication.account.darkMode
    : authentication.darkMode,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [
    AUTHORITIES.ADMIN
  ]),
  isGeeki: hasAnyAuthority(authentication.account.authorities, [
    AUTHORITIES.GEEKI
  ]),
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled
});

const mapDispatchToProps = { getSession, getProfile };
//const mapDispatchToProps = { getSession };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
