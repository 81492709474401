import { useState } from "react";
import "../CryptoTable.css";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import TargetEditor from "./TargetEditor";
import CryptoGauge from "./CryptoGauge";
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import { StatusEnum } from "../data/CryptoDataType";
import StatusEditor from "./StatusEditor";
import SellIcon from '@mui/icons-material/Sell';


const CryptoRowTarget = (props: any) => {

    let [updated, setUpdated] = useState(false);

    const toggleUpdated = () => {
        setUpdated(!updated);
        props.partialRefresh(false);
    }
    const partialRefresh = () => {
        props.partialRefresh(false);
    }

    const onRemove = () => {
        props.onRemoveTarget(props.targetPos);
    }

    let row = props.row;
    let targetPos = props.targetPos;
    let item = props.item;
    
    if (!row.targets) {
        return (<TableCell className="rowTarget" key={"key01" + item.name + item.site + Math.random()} align="right"></TableCell>);
    } else if ((!row.targets[targetPos])) {
        return (<TableCell className="rowTarget" key={"key01" + item.name + item.site + Math.random()} align="right"></TableCell>);
    } else if (row.targets[targetPos].sold && props.viewMode) {
        return (<TableCell key={"key01" + item.name + item.site + Math.random()} align="right"
            className="rowTarget sold" colSpan={row.targets[targetPos].bagPerc}>vendu</TableCell>);
    } else if (row.targets.length > targetPos) {
        let numPrice = row.targets[targetPos].price;
        let price = (numPrice == null || numPrice === undefined ? null : numPrice.toFixed(2));
        let numBag = row.targets[targetPos].bag;
        let bag = (numBag == null || numBag === undefined ? null : numBag.toFixed(2));

        let target1Perc = row.targets[targetPos].perc;
        let rowColor = "rowColorDefault";
        if (props.viewMode) {
            if (target1Perc && target1Perc > 80) {
                rowColor = "rowColorWarning3";
            } else if (target1Perc && target1Perc > 70) {
                rowColor = "rowColorWarning2";
            } else if (target1Perc && target1Perc > 50) {
                rowColor = "rowColorWarning1";
            }
        }

        return (<TableCell  align="right" className={"rowTarget removeTarget " + rowColor}
            colSpan={row.targets[targetPos].bagPerc}>
                {props.viewMode &&(row.targets[targetPos].status === StatusEnum.Order)
                && (            <div className="fixedWidth">{row.targets[targetPos].status === StatusEnum.Order && <span className="targetOrdered">
                <Tooltip title="Ordre de vente"><SellIcon />
                </Tooltip>
            </span>}
            
                {props.viewMode ? <Tooltip title="Bag à vendre"><span>bag: {bag}</span></Tooltip>: <br />}</div>)}

                {props.viewMode && <Tooltip title="% Objectif réalisé"><span>
                <CryptoGauge viewMode={props.viewMode} key={"key03" + item.name + item.site + Math.random()}
                reverse={false} perc={row.targets[targetPos].perc} /></span></Tooltip>}

            <TargetEditor
                tooltip="Cours de vente en USDT"
                className="fixedWidth"
                targetPos={targetPos} val={row} prop="rate"
                viewMode={props.viewMode}
                partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
                onChange={toggleUpdated} type="currency" key={"key12" + item.name + item.site + Math.random()} />
                <div className="fieldEditor-container"><TargetEditor
                    tooltip="Pourcentage du bag"
                    className="fixedWidth"
                    targetPos={targetPos} val={row} prop="bagPerc"
                    viewMode={props.viewMode}
                    partialRefresh={partialRefresh} fullRefresh={props.fullRefresh}
                    onChange={toggleUpdated} type="percentage" key={"key131" + item.name + item.site + Math.random()} /></div>
            {props.viewMode ? <Tooltip title="Montant de l'objectif"><div>$ {price}</div></Tooltip>: <br />}

                
                

            {

                (!props.viewMode) && <Tooltip title="Statut de l'ordre"><div><StatusEditor targetPos={targetPos} val={row} /></div></Tooltip>}

            {row.targets.length - 1 === targetPos &&
                <br />}
            {row.targets.length - 1 === targetPos &&
                <Tooltip title="Supprimer objectif"><IconButton onClick={onRemove}>
                    <RemoveIcon />
                </IconButton></Tooltip>
            }
        </TableCell>
        );
    } else {
        return (<TableCell key={"key01" + item.name + item.site + Math.random()} align="right"></TableCell>);
    }

}

export default CryptoRowTarget;