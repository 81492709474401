import "./Twitch.scss";
import React from "react";
import { TwitchEmbed } from "react-twitch-embed";

interface IProps {
  channel: string;
}

class Twitter extends React.Component<IProps> {

  render() {
    return (
      <div id="monitorTwitch" key={"tcc" + Math.random()}>
        <div id="monitorTwitchscreen" key={"tccd" + Math.random()}>
          <TwitchEmbed width="100%" darkMode={true} channel={this.props.channel} />
        </div>
      </div>
    );
  }
}

export default Twitter;
