import React from "react";
import ErrorBoundary from "../../shared/error/error-boundary";

export interface IErrorBoundaryRoutes {
  path?: string;
  element: any;
}

export default class ErrorBoundaryRoute extends React.Component<IErrorBoundaryRoutes>{

  render() {
        return (<ErrorBoundary>
          <this.props.element />
        </ErrorBoundary>);
  }

}